@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin small {
  @media (max-width: 550px) {
    @content;
  }
}

@mixin extraSmall {
  @media (max-width: 365px) {
    @content;
  }
}
